<template>
  <page :data="pageData" :is-child="_isChild" @refresh="refresh">
    <template #cmslefttype(vaddress-listitem)="{data: d}">
      <list-item
        :data="d.Data"
        @title-action-click="edit({})"
        @action-click="({ action }) => handleAction(action, d)">
      </list-item>
    </template>

    <template #aftercmsleftcomponents>
      <modal-form-page v-bind="modalProps('form')" @close="close">
      </modal-form-page>
    </template>
  </page>
</template>

<script>
import ModalFormPage from '../../base/ModalFormPage'
import CMSPage from '../../base/CMSPage'
export default {
  extends: CMSPage,
  components: {
    ModalFormPage,
    ListItem: () => import('@/components/vaddress/ListItem')
  },
  methods: {
    handleAction (a, d) {
      if (a.Id === 'delete') {
        this.$buefy.dialog.confirm({
          message: this.config.DeleteConfirmMessage,
          type: 'is-primary',
          onConfirm: async () => {
            const loading = this.$buefy.loading.open()
            try {
              await this.$baseApi.delete('/user/addresses?Oid=' + d.Data.Oid)
              this.init()
            } catch (err) {
              alert(err.message)
            } finally {
              loading.close()
            }
          }
        })
      } else {
        this.edit(d.Data)
      }
    },
    edit (d) {
      const url = '/user/addresses' + (d.Oid ? `/edit?Oid=${d.Oid}` : '/create')
      this.openModal('form', { url })
    },
    close () {
      this.$store.commit('service/SET_CONDITION_NOTIF', false)
      this.closeModal('form')
      this.init()
    }
  }
}
</script>

<style>

</style>
